import styled from '@emotion/styled';
import React from 'react';
import {rhythm, scale} from '../utils/typography';

import Layout from '../components/layout';
import SEO from '../components/seo';

const About = () => {
  return (
    <Layout>
      <SEO title="About" />
      <div>
        <h3> About Roy </h3>
        <p>
          <Span>
            Since I have started coding I was always fascinated with tests, automation and DevOps. I
            was always striving to be as efficient as I can be. I have been designing and developing
            automation tests and processes for BackEnd and FrontEnd. I try to find tedious
            reoccurring manual procedures and try to automate them. Colleagues would say that I
            enjoy finding, fixing and testing all sorts of bugs. I like new technologies and
            processes, but still hold tribute to the old ways that got us to where we are now.
          </Span>
          <Span>
            I like to mentor and teach, contact me if you have questions or constructive criticism.
          </Span>
        </p>
        <p>
          <BigHeader>I love automation!! 💓 🤖</BigHeader>
          <Email>
            Feel free to send me an <a href="mailto:roy@testbrew.com">email</a>
          </Email>
        </p>
      </div>
    </Layout>
  );
};

export default About;

const Span = styled.span`
  display: block;
  margin-right: 1rem;
  text-indent: 20px;
`;

const Email = styled.span`
  display: block;
  margin-top: ${rhythm(2 / 3)};
`;

const BigHeader = styled.p`
  ${scale(1 / 2)};
  display: block;
  text-align: center;
  font-family: 'Roboto', sans-serif;
`;
